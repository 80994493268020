import React from 'react';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';

const Layout = ({ children }) => {
    
    const title = "SavageJoan";
    
    return ( 
        <div>
            <Header />
            <div className="bg-black min-h-screen">
                <main className="min-h-screen bg-black text-white">
                    {children}
                </main>
            
            </div>
            
            <Footer title={title} />
        </div>
     );
}
 
export default Layout;