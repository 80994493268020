import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import imgbg from "../../../data/imagenesbg";
const Home = ()=>{
    const [photo, setPhoto] = useState(1);
    useEffect(() => {
        const interval = setInterval(() => {
          change();
        }, 10000);
        return () => {
            clearInterval(interval);
          };
        }, [photo]);
        const change = () => {
            if (photo === 2) {
              setPhoto(1);
              return;
            }
        
            setPhoto((prev) => prev + 1);
          };
          const returnPhotoURL = () => {
            switch (photo) {
              case 1:
                return imgbg.bg1;
              case 2:
                return imgbg.bg2;
              default:
                return imgbg.bg1;
            }
          };
    return(
        <div >
        <div  style={{
            backgroundImage: `url(${returnPhotoURL()})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
            }}
            className=" hidden lg:flex flex-col min-h-screen min-w-screen">

            <div className="bg-black bg-opacity-60 flex flex-col items-center justify-center min-h-screen shadow-lg">
                <div className="border-2 border-double w-3/4 flex flex-col items-center justify-center py-2 ">
                <h3 className="text-xl text-center font-bold" >Innovación y Calidad por Más de 60 Años.</h3>
                <p className="text-lg text-center font-thin italic">Expertos en Soluciones Especializadas</p>
                <p className="text-lg text-center py-4">Con más de 60 años de experiencia, fabricamos productos químicos para el procesado de materiales sensibles y accesorios de acero inoxidable para odontología.</p>
                <p className="text-lg text-center font-bold py-2">Descubra cómo nuestra experiencia impulsa la excelencia en cada proyecto.</p>
            <Link to='/fotografia-Blancoynegro' className='bg-red-500 rounded p-2 shadow-lg hover:bg-yellow-400'>Ver productos</Link>
                </div>
            </div>

            
            </div>

            <div style={{
            backgroundImage: `url(${imgbg.bg1})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
            }}
             className="bg-gray-400 bg-opacity-60 text-black flex flex-col items-center justify-center min-h-screen lg:hidden shadow-lg">
                <div className="border-2 border-double w-3/4 flex flex-col items-center justify-center p-2 bg-gray-300 bg-opacity-55">
                <h3 className="text-xl text-center font-bold" >Innovación y Calidad por Más de 60 Años.</h3>
                <p className="text-lg text-center font-thin italic">Expertos en Soluciones Especializadas</p>
                <p className="text-lg text-center py-4">Con más de 60 años de experiencia, fabricamos productos químicos para el procesado de materiales sensibles y accesorios de acero inoxidable para odontología.</p>
                <p className="text-lg text-center font-bold">Descubra cómo nuestra experiencia impulsa la excelencia en cada proyecto.</p>
            <Link to='/fotografia-Blancoynegro' className='bg-red-500 text-yellow-400 font-bold rounded p-2 shadow-lg hover:text-red-500 hover:bg-yellow-400'>Ver productos</Link>
                </div>
            </div>
        </div>

    )
}
export default Home