import { getProductosDental } from "../../../data/productosDental"
import Productos from "./productos"
const RadiologiaDental = ()=>{
    const productosDental = getProductosDental()
    return(
        <div className='flex flex-col  justify-center text-center m-auto'>
            <Productos  productos={productosDental} />
        </div>
    )
}
export default RadiologiaDental