const imagenesBYN = {
    PQ7: require("./img/PQ-7.jpg"),
    R76:require('./img/R-76.jpg'),
    PQ8:require('./img/PQ-8.jpg'),
    PQ9:require('./img/PQ-9.jpg'),
    C2:require('./img/C-2.jpg'),
    ANTIVELO:require('./img/ANTIVELO.webp'),
    DETENEDOR:require('./img/DETENEDOR.jpg'),
    ELIMINADOR:require('./img/ELIMINADOR.jpg'),
    HUMECTANTE:require('./img/HUMECTANTE.jpg')
}

const productosbyn = [
    {
        id: 1,
        categoria:'Reveladores para negativos',
        nombre: 'PQ-7',
        subtitulo:'Grano Ultra Fino Concentrado (Liquido) (dilución 1+3)',
        descripcion:'Permite obtener ampliaciones de hasta 30 diámetros. Su efecto compensador da un gran detalle en las sombras, con gradación que compensa los tonos, obteniéndose buenos resultados con malas condiciones de luz ambiente.',
        pdf:'https://drive.google.com/file/d/1xU9_4uc5uX8EjwGtE8N288xWWcHFp6t3/view?usp=drive_link',
        img: imagenesBYN.PQ7
        
        
    },{
        id: 2,
        categoria:'Reveladores para negativos',
        nombre: 'R-76',
        subtitulo:'Grano Ultra Fino Concentrado (Polvo)',
        descripcion:'De aplicación universal en películas cuya sensibilidad esta entre 32 y 400 ISO, rinde negativos de contraste medio con excelente definición.',
        pdf:'https://drive.google.com/file/d/1RwW9D9oC9NASeS8f-wt15NPs_sVkc0zU/view?usp=drive_link',
        img: imagenesBYN.R76
        
    },{
        id: 3,
        categoria:'Reveladores universales para papeles y negativos',
        nombre: 'PQ-8',
        subtitulo:'Logra alto contraste y balance perfecto en tus fotos B/N.',
        descripcion:'El PQ-8 es un revelador universal en polvo para películas y papeles blanco y negro. Fácil de preparar, rinde 1 litro de solución, suficiente para aproximadamente 80 hojas tamaño 18x24 cm. Garantiza imágenes nítidas, con buen contraste y balance de grises. Ideal para fotógrafos que buscan calidad y confiabilidad en sus procesos analógicos.',
        pdf:'https://drive.google.com/file/d/1yca5MajWL7Aeh-RHnWLoihCLKKBfrA_h/view?usp=sharing',
        img: imagenesBYN.PQ8
        
    },
    {
        id: 4,
        categoria:'Reveladores universales para papeles y negativos',
        nombre: 'PQ-9',
        subtitulo:'Revelador universal para papeles y películas blanco y negro',
        descripcion:'El PQ-9 de Romek es un revelador fotográfico universal diseñado para obtener un contraste moderado y resultados consistentes en papeles y películas blanco y negro. Su formulación avanzada garantiza una calidad óptima, especialmente en trabajos fotográficos artísticos, publicitarios y sociales. Es eficiente y versátil, manteniendo su efectividad incluso después de varios usos, lo que lo convierte en una solución ideal para fotógrafos profesionales.',
        pdf:'https://drive.google.com/file/d/1fTOID3b35VYueKfvMLSCVkOf9maSBwuw/view?usp=drive_link',
        img: imagenesBYN.PQ9
        
    },
    {
        id: 5,
        categoria:'Reveladores para papel',
        nombre: ' Revelador C2',
        subtitulo:'Líquido concentrado (dilución 1+3)',
        descripcion:'Ideal para trabajos de proceso rápido obteniéndose negros puros y balanceados. Especial para papeles RC, bromuros y cloro bromuros. Mantiene marcada latitud de los tiempos de procesado.',
        pdf:'https://drive.google.com/file/d/1dXDW6e20ulRSHo0KvFMFwLC-7eIyRZdW/view?usp=sharing',
        img: imagenesBYN.C2
        
    },
    {
        id: 6,
        categoria:'Preparados Especiales',
        nombre: 'Antivelo',
        subtitulo:'Evita el velo en materiales sensibles',
        descripcion:'Especial para obtener un revelado perfecto de papeles fotográficos, películas y placas radiográficas, todas ellas pasadas de fechas o envejecidas, de gran utilidad para el forzado de películas sub. expuestas. Su uso suprime totalmente los efectos de velo grisado o velo químico dando blancos puros o aceptables según el estado del material.',
        pdf:'https://drive.google.com/file/d/1yjGoguTeyikgGZ8Kquizw_VvajV5PQ0E/view?usp=drive_link',
        img: imagenesBYN.ANTIVELO
        
    },
    {
        id: 7,
        nombre: 'Detenedor',
        categoria:'Preparados Especiales',
        subtitulo:'“STOP” con indicado visual de agotamiento.',
        descripcion:'Se utiliza antes del baño fijador y es apto para papeles como para placas y películas. Su uso posibilita prolongar la vida útil de los fijadores neutralizando la alcalinidad de los reveladores y a medida que se agota cambia su coloración del amarillo al azul.',
        pdf:'https://drive.google.com/file/d/1JaX4HEFIYuZj-OQ3kW90d-UOfFhfp02G/view?usp=sharing',
        img: imagenesBYN.DETENEDOR
        
    },
    {
        id: 8,
        nombre: 'Eliminador de Hiposulfito',
        categoria:'Preparados Especiales',
        subtitulo:'Neutralizador por ósmosis',
        descripcion:'Especial para obtener un revelado perfecto de papeles fotográficos, películas y placas radiográficas, todas ellas pasadas de fechas o envejecidas, de gran utilidad para el forzado de películas sub. expuestas. Su uso suprime totalmente los efectos de velo grisado o velo químico dando blancos puros o aceptables según el estado del material.',
        pdf:'https://drive.google.com/file/d/19hlZ5YSQj9mBqGQb6ZEylX5ErFQ6yPcS/view?usp=drive_link',
        img: imagenesBYN.ELIMINADOR
        
    },
    {
        id: 9,
        nombre: 'Humectante',
        categoria:'Preparados Especiales',
        subtitulo:'Tensioactivo no iónico - solución total.',
        descripcion:'Especial para obtener un revelado perfecto de papeles fotográficos, películas y placas radiográficas, todas ellas pasadas de fechas o envejecidas, de gran utilidad para el forzado de películas sub. expuestas. Su uso suprime totalmente los efectos de velo grisado o velo químico dando blancos puros o aceptables según el estado del material.',
        pdf:'https://drive.google.com/file/d/1x1QnK16yRE4rK17_0rzzvfvoYuBDMCcu/view?usp=drive_link',
        img: imagenesBYN.HUMECTANTE
        
    },
    {
        id: 10,
        nombre: 'Limpia Películas',
        categoria:'Preparados Especiales',
        subtitulo:'Antiestático protector- nueva formula.',
        descripcion:'Ideal para limpieza de todo material negativo fotográfico. Su efecto antiestático elimina la carga de electricidad estática acumulada por la fricción evitando la adherencia de tierra y /o polvillo en las películas o placas. Quita las manchas de los negativos producidas después del lavado final . Contiene activos antiestáticos y protectores. Puede ser utilizado también en la limpieza de slides , cristales , de condensadores , porta negativos, prensas , copiadoras, etc.',
        pdf:'https://drive.google.com/file/d/1tB444nCT0lZ9iNyzW5IO9q9MOK3xb54w/view?usp=drive_link',
        img: 'placeholder'
        
    },
    {
        id: 11,
        nombre: 'Virador Rojo R-15',
        categoria:'Preparados Especiales',
        subtitulo:'Virador para papeles.',
        descripcion:'Permite obtener un virado sobre cualquier tipo de papel. De uso simple y rendimiento altamente eficaz pudiendo ser usado por profesionales y aficionados sin inconvenientes. Otorga un excelente tono que va desde el sepia al pardo, rojo claro y rojo oscuro en pocos segundos, pudiendo detenerse el proceso cuando el tono se adecúe al gusto del usuario.',
        pdf:'https://drive.google.com/file/d/11iVh7O4gcld864Bm8tLlAAXz73S5npun/view?usp=sharing',
        img: 'placeholder'
        
    },
    {
        id: 12,
        nombre: 'Virador Sepia S-14',
        categoria:'Preparados Especiales',
        subtitulo:'Virador para papeles',
        descripcion:'Práctico y seguro para el viraje al color sepia de cualquier tipo de papel, otorgando excelentes tonos. De un gran rendimiento permite su utilización por parte de los aficionados y profesionales sin inconvenientes técnicos; es el tono mas indicado para fotografías de retratos a luz natural o artificial, mejora así mismo todas las fotografías que hayan sido sobre expuestas o sobre reveladas. Permite variar el tono sepia en todo su espectro. Hace impedecederas sus fotos.',
        pdf:'https://drive.google.com/file/d/1CoiycwvRjXleDPYtWwXhOVifUNHSq2Xy/view?usp=sharing',
        img: 'placeholder'
        
    },
]


export const getProductosBYN = () =>{
    return productosbyn
}