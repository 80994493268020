import { getProductosInox } from "../../../data/productosInox"
import Productos from "./productos"
const AceroInox = ()=>{
    const productosInox = getProductosInox()
    return(
        <div className='flex flex-col  justify-center text-center m-auto'>
            <Productos  productos={productosInox} />
        </div>
    )
}
export default AceroInox