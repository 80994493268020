export const styles = {
    header: `flex flex-wrap px-4 py-5 bg-red-600 m-auto`,
    headerLinkActive: `text-yellow-500 text-sm font-bold px-5 underline block hover:text-white`,
    headerLinkInactive: `text-yellow-500 text-sm font-bold px-5 block hover:text-white`,

    headerMenuVisible: `m-auto w-full block flex-grow lg:flex lg:items-center lg:justify-center lg:w-3/4 text-center lg:flex-wrap`,
    headerMenuHidden: `w-full block flex-grow lg:flex lg:items-center lg:w-auto text-center lg:flex-nowrap hidden`,

    footer: `text-center py-4 bg-red-600`,
    textFooter: `text-white text-sm`
}