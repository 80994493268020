import { getProductosBYN } from "../../../data/productosbyn"
import Productos from "./productos"
const Blancoynegro = ()=>{
    const productosbyn = getProductosBYN()
    return(
        <div className='flex flex-col  justify-center text-center m-auto'>
            <Productos  productos={productosbyn} />
        </div>
    )
}
export default Blancoynegro