import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Layout from "./views/screens/layout";
import Home from "./views/screens/home";
import RadiologiaDental from "./views/screens/radiologiadental";
import RadiologiaClinica from "./views/screens/radiologiaclinica";
import Blancoynegro from "./views/screens/blancoynegro";
import Contact from "./views/screens/contacto";
import AceroInox from "./views/screens/aceroinox";

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/RadiologiaClinica" exact element={<RadiologiaClinica />} />
            <Route path="/RadiologiaDental" element={<RadiologiaDental />} />
            <Route path="/Fotografia-Blancoynegro" element={<Blancoynegro />} />
            <Route path="/AceroInoxidable"  element={<AceroInox />} />
            <Route path="/Contacto"  element={<Contact />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
