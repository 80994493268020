const imgDental = {
    td3Dental:require('./img/TD-3DENTAL.jpg'),
    td4Dental:require('./img/TD-4DENTAL.jpg'),
    gamalTD5:require('./img/GAMALTD-5.jpg'),
    gamalTD6:require('./img/GAMALTD-6.png'),    
}
const productosDental = [
    {
        id:1,
        nombre:'REVELADOR DENTAL TD-3',
        categoria:'Linea Romek',
        subtitulo:'Para películas radiográficas dentales',
        descripcion:'Revelador líquido concentrado y balanceado que garantiza máximo detalle en medios tonos y alta definición. Ahorra tiempo con soluciones listas, evita desperdicios y ofrece un revelado uniforme y seguro. Resistente a la oxidación, asegura imágenes de calidad con dosis reducidas de radiación.',
        pdf:'https://drive.google.com/file/d/1VJZMPXpXrq2biShCzEm0LhMqnbqrEyno/view?usp=drive_link',
        img:imgDental.td3Dental
    },
    {
        id:2,
        nombre:'FIJADOR DENTAL TD-4',
        categoria:'Linea Romek',
        subtitulo:'Para películas radiográficas dentales',
        descripcion:'Se trata de un fijador liquido concentrado, de acción ultra rápida y larga vida útil pudiéndose utilizar con cualquiera de las marcas de película, fijándolas en menor tiempo que los fijadores comunes, siendo su rendimiento mayor a todo lo conocido. No produce manchas amarillas, ni da emanaciones sulfurosas ni ácidas.',
        pdf:'https://drive.google.com/file/d/1pZinobt5uvn0h0ZTiAvGmUg3Q2GXQGVA/view?usp=drive_link',
        img:imgDental.td4Dental
    },{
        id:3,
        nombre:'REVELADOR   GAMAL  TD5',
        categoria:'Linea Gamal',
        subtitulo:'Para el procesado de películas radiográficas dentales',
        descripcion:'Dentro de la linea  de productos  químicos radiográficos de nuestra empresa hemos desarrollado un nuevo revelador de placas dentales  que combina un resultado de proceso muy aceptable y una economía de costos para el profesional exigente. Es un revelador liquido concentrado que permite obtener placas con excelente definición y amplia gama de grises',
        pdf:'https://drive.google.com/file/d/1PiHbqYkxzN7G7kDwUmSh6n-Yx7Al6Ukb/view?usp=drive_link',
        img:imgDental.gamalTD5
    },{
        id:4,
        nombre:"FIJADOR  GAMAL TD6",
        categoria:'Linea Gamal',
        subtitulo:'Para el procesado de películas radiográficas dentales',
        descripcion:'Complementándose con el revelador  ofrecemos este producto fabricado con las mejores materias primas del mercado y excelente procesos de fabricación y control. Es un fijador liquido concentrado, de acción ultra rápida, alto rendimiento y de costo extremadamente accesible.',
        pdf:'https://drive.google.com/file/d/1tmafFSjxoBJ8ePzYPC82ntBK91mimJwD/view?usp=drive_link',
        img:imgDental.gamalTD6
    }
]
export const getProductosDental = () =>{
    return productosDental
}