const imgInox = {
    portaAlicates:require('./img/portaalicate.jpg'),
    portaJeringas:require('./img/portajeringas.jpg'),
    bandejas:require('./img/bandejas.jpg')
}
const productosinox = [
    {
        id:1,
        nombre:'Porta Alicates',
        categoria:'Porta Alicates',
        subtitulo:'Para consultorios odontológicos.',
        descripcion:'Fabricado en acero inoxidable mate, este elemento esta pensado para asociarse con la imagen de limpieza que permite al acero inoxidable, y a la elegancia que debe distinguir a los consultorios de los profesionales de la odontología. Se puede personalizar con grabado laser',
        pdf:'placeholder',
        img:imgInox.portaAlicates
    },
    {
        id:2,
        nombre:'Porta Jeringas',
        categoria:'Porta Jeringas',
        subtitulo:'De jeringas de restauración, para consultorios odontológicos.',
        descripcion:'Fabricado en acero inoxidable mate, este elemento está pensado para asociarse con la imagen de limpieza que permite el acero inoxidable y a la elegancia que debe distinguirá los consultorios de los profesionales de la odontología. La presentación se realiza en caja contenedora de 40 unidades, adecuadamente protegida para evitar golpes y ralladuras.',
        pdf:'placeholder',
        img:imgInox.portaJeringas
    },{
        id:3,
        nombre:'BANDEJAS LISAS Y ACANALADAS',
        categoria:'BANDEJAS LISAS Y ACANALADAS',
        subtitulo:'Tamaño 20x20x10 PARA INSTRUMENTAL.',
        descripcion:'Fabricado en acero inoxidable mate, este elemento está pensado para el estudiante o el profesional de la odontología.',
        pdf:'placeholder',
        img:imgInox.bandejas
    },
]
export const getProductosInox = () =>{
    return productosinox
}