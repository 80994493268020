import { getProductosClinica } from "../../../data/productosClinica"
import Productos from "./productos"
const RadiologiaClinica = ()=>{
    const productosClinica = getProductosClinica()
    return(
        <div className='flex flex-col  justify-center text-center m-auto'>
            <Productos  productos={productosClinica} />
        </div>
    )
}
export default RadiologiaClinica