

    const Producto =({producto}) =>{
    return(
        <div className="flex flex-col items-center text-center justify-center bg-yellow-500 m-3 py-4 rounded-xl drop-shadow-lg lg:w-80">
            {producto.img!=="placeholder"?<img className='max-w-24' src={producto.img} alt={producto.nombre} />:""} 
            <h2 className='font-bold lg:text-xl text-black'>{producto.nombre}</h2>
            <p className="font-bold text-black p-5">{producto.subtitulo}</p>
        <p className="font-bold text-gray-800 ">{producto.descripcion}</p>
        <a className='bg-red-500 p-5 rounded hover:bg-red-600 mt-3' href={producto.pdf}>Ver Ficha </a>

    </div>
)
}

export default Producto