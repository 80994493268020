const logos ={
    logoFB : require("../../../data/img/logoFacebook.png"),
    logoMail : require("../../../data/img/logoMail.png"),
    logoWpp : require("../../../data/img/logoWpp.png")
}
const Footer = ({title}) =>{
    return (
        <footer className="flex sm:flex-col bg-red-600 divide-y divide-double divide-yellow-600">
            <div className="m-2">
                <img  src="/romek-logo.png" className="w-32 m-auto  " alt="romek logo" /></div>
                <div className="hidden md:flex p-12  md:flex-row  md:justify-evenly ">
                <a className='font-bold text-gray-200'href="/home">Home</a>
                <a href="/Fotografia-blancoynegro" className='font-bold text-gray-200'>Fotografia Blanco y negro</a>
                <a href="/radiologiadental"className='font-bold text-gray-200'>Radiologia Dental</a>
                <a href="/radiologiaclinica"className='font-bold text-gray-200'>Radiologia Clinica</a> 
                <a href="/aceroInoxidable"className='font-bold text-gray-200'>Acero Inoxidable</a>
                </div> 
                <div className="flex flex-row justify-center text-center items-center">
                    <a href="https://www.facebook.com/romek.quimicos" className="font-bold p-2"><img className='h-8 ' src={logos.logoFB} alt="facebook.com/romek.quimicos" /></a>
                    <a href="mail-to:correo@romekquimicos.com.ar" className="p-2"><img className='h-8 ' src={logos.logoMail} alt="correo@romekquimicos.com.ar" /></a>
                    <a href="wa.me/1173663945" className="p-2"><img className='h-8' src={logos.logoWpp} alt="11-7366-3945" /></a>
                </div>
        </footer>
    )
}
export default Footer