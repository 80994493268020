import Producto from "./producto";

 const Productos = ({productos}) =>{

    const categorias = productos.reduce((acc, producto) =>{
        acc[producto.categoria] = acc[producto.categoria] || [];
        acc[producto.categoria].push(producto);
        return acc;
    },{})
    return (
        <div  classname="flex flex-col justify-center text-center">
        {Object.entries(categorias).map(([categoria, items]) => (
          <div key={categoria}  className="flex flex-col justify-between justify-center text-center bg-red-600 m-auto py-7" >
            <h1 className="font-bold text-2xl">{categoria}</h1>
            <ul className='flex flex-col lg:flex-row justify-evenly lg:flex-wrap'>
              {items.map((item) => (
                <Producto producto = {item} />
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

export default Productos
