const imgClinica = {
    td3manual:require('./img/TD-3.jpg'),
    td4manual:require('./img/TD-4(CLINICO).jpg'),
    td3auto:require('./img/TD-3AUTOMATICO.jpg'),
    td4auto:require('./img/TD-4AUTOMATICO.jpg')
}
const productosClinica = [
    {
        id:1,
        nombre:'Revelador  TD-3 CLINICO',
        categoria:'Proceso Manual',
        subtitulo:'Revelador líquido concentrado , para el correcto revelado de placas radiográficas.',
        descripcion:'Revelador  TD-3 CLINICO  posee máxima energía y correcto balance, posee efecto conpensador, ofreciendo un gran detalle en los medios tonos compensando las placas que requieran el máximo de rendimiento y una definición perfecta. Permite se rellenado manteniendo sus propiedades originales.',
        pdf:'https://drive.google.com/file/d/1YNb9qPA7tvrzCvQposNgiO5vKuOcXYtQ/view?usp=drive_link',
        img:imgClinica.td3manual
    },{
        id:2,
        nombre:'Fijador  TD-4 CLINICO',
        categoria:'Proceso Manual',
        subtitulo:'Fijador liquido concentrado ultra rapido manual para el correcto fijado de placas radiográficas.',
        descripcion:'El TD-4 CLINICO  posee una perfecta dosificación con extraordinaria rapidez de fijado que oscila entre 1 y 2 minutos, permite gran rendimiento y larga vida útil en tanques o cubetas, apto para utilizar junto con el endurecedor de emulsiones a altas temperaturas .',
        pdf:'https://drive.google.com/file/d/1YNb9qPA7tvrzCvQposNgiO5vKuOcXYtQ/view?usp=drive_link',
        img:imgClinica.td4manual
    },
    {
        id:3,
        nombre:'Revelador TD-3 Automatico',
        categoria:'Proceso Automatico',
        subtitulo:'Revelador rellenado liquido concentrado para procesar películas radiográficas en maquinas automáticas con excelentes resultados.',
        descripcion:'EL TD-3 CLINICOAUTOM 90¨permite revelar siempre con soluciones de balance correcto, obteniéndose placas de gran contraste, máximo detalle en las sombras y ausencia de velo, tiene incorporado un agente endurecedor para asegurar la estabilidad y textura de las emulsión radiográfica, mediante el agregado de estárter (arrancador) se convierte en baño inicial.',
        pdf:'https://drive.google.com/file/d/1BGmwthsjYgM1NExxFOXhcnJjRuQYu6S1/view?usp=drive_link',
        img:imgClinica.td3auto
    },{
        id:4,
        nombre:'Fijador TD-4 Automatico',
        categoria:'Proceso Automatico',
        subtitulo:'Fijador y rellenador liquido concentrado ultra rapido para procesar películas radiográficas en maquinas automáticas.',
        descripcion:'El FIJADOR  TD-4 CLINICO  AUTOM 90 tiene incorporado un endurecedor para la protección de las emulsiones contra rayaduras, reticulaciones y desprendimientos permitiendo su uso en modernas maquinas automáticas ofreciendo gran resistencia a la oxidación.',
        pdf:'https://drive.google.com/file/d/1BGmwthsjYgM1NExxFOXhcnJjRuQYu6S1/view?usp=drive_link',
        img:imgClinica.td4auto
    }
]
export const getProductosClinica = () =>{
    return productosClinica
}